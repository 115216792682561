<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content} | SugarStar` : `SugarStar` }}</template>
    </metainfo>
    <vue-loader-common></vue-loader-common>
    <vue-user-common></vue-user-common>
    <vue-web-socket-common></vue-web-socket-common>
    <router-view/>

</template>

<script>
import vueLoaderCommon from '@/components/LoaderCommon.vue'
import vueUserCommon from '@/components/UserCommon.vue'
import vueWebSocketCommon from '@/components/WebsocketCommon.vue'
import {useMeta} from 'vue-meta'

import("@/assets/css/app.css")

export default {
    components: {
        vueLoaderCommon,
        vueUserCommon,
        vueWebSocketCommon
    },
    created() {
        useMeta({
            title: '在茫茫星海中遇見1%的心動',
            // htmlAttrs: {lang: 'en', amp: true}
        })
        document.addEventListener("DOMContentLoaded", this.onWindowLoad);
        this.setDeviceWidth()
    },
    async mounted() {
        if (this.confDeviceType === 'auto') {
            await this.$store.commit('detectDeviceType', document.body.clientWidth)
            this.deviceType = await this.$store.getters.getDeviceType
        } else {
            await this.$store.commit('setDeviceType', this.confDeviceType)
            this.deviceType = this.confDeviceType
        }
        switch (this.deviceType) {
            case 'mobile':
                import("@/assets/css/mobile/loader-common.css")
                import("@/assets/css/mobile/layout-common.css")
                break;
            case 'pc':
                break;
        }
        window.addEventListener('resize', this.setDeviceWidth);
    },
    methods: {
        setDeviceWidth() {
            this.$store.commit('setDeviceWidth', document.body.clientWidth)
        },
    }
}
</script>
