<template>
    <div class="loader" v-bind:class="{ 'hide': isHide}">
        <div class="content d-flex justify-content-center">
            <img src="@/assets/images/logo.png">
        </div>
    </div>
</template>

<script>
import {useCookies} from "vue3-cookies";

export default {
    name: 'LoaderCommon',
    setup() {
        const {cookies} = useCookies();
        return {cookies};
    },
    created() {
        document.addEventListener("DOMContentLoaded", this.onWindowLoad);
    },
    data() {
        return {
            isHide: false,
            deviceType: 'auto'
        }
    },
    methods: {
        onWindowLoad() {
            setTimeout(() => this.isHide = true, 1000)
        },
        reload() {
            location.reload()
        }

    },
    async mounted() {
        await this.$store.commit(
            'setAxiosHeaders',
            {
                key: 'reqHost',
                val: location.protocol + '//' + location.host
            }
        )
        if (this.confDeviceType === 'auto') {
            await this.$store.commit('setDeviceType', document.body.clientWidth)
            this.deviceType = this.$store.state.deviceType
        } else {
            this.deviceType = this.confDeviceType
        }

        if (this.deviceType === 'pc')
            window.addEventListener('resize', this.reload);

        let apiUrl = document.cookie.match('(^|;)\\s*apiUrl\\s*=\\s*([^;]+)')?.pop() || '';
        if (apiUrl === '')
            this.cookies.set("apiUrl", encodeURI(this.confApiUrl));

        switch (this.deviceType) {
            case 'mobile':
                import("@/assets/css/mobile/loader-common.css")
                import("@/assets/css/mobile/layout-common.css")
                break;
            case 'pc':
            default:
                break;
        }
    }

}
</script>
