<template>
    <div class="websocket-info">

    </div>
</template>
<script>

export default {
    name: 'WebsocketComment',
    setup() {

    },
    created() {
        this.$watch(
            () => this.$store.state.socket.isConnected,
            () => {
                if (this.$store.state.socket.isConnected) {
                    this.sendUserInfo()
                } else {
                    this.$router.go(this.$router.currentRoute)
                }
            }
        )
        this.$watch(
            () => this.$store.state.isLogin,
            () => {
                this.sendUserInfo()
            }
        )
    },
    data() {
        return {
            isLogin: false,
            deviceWidth: 0,
        }
    },
    async mounted() {
        this.isLogin = await this.$store.state.isLogin
        this.sendUserInfo()
        this.deviceWidth = this.$store.state.deviceWidth
    },
    components: {},
    methods: {
        sendUserInfo() {
            if (
                this.$store.state.userInfo &&
                this.$store.state.socket.isConnected &&
                this.$store.state.isLogin
            )
                this.$socket.sendObj(
                    {
                        type: 'user',
                        value: this.$storage.getStorageSync(this.confStoragePrefix + 'uuid'),
                        userInfo: this.$storage.getStorageSync(this.confStoragePrefix + 'userInfo'),
                    });
        }
    },

}
</script>