import "bootstrap"
import 'bootstrap/dist/js/bootstrap.min.js'
import '@/assets/custom.scss'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/regular.min.js'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vue3Storage from "vue3-storage";
import Toaster from "@meforma/vue-toaster"
import VueNativeSock from "vue-native-websocket-vue3";
import { createMetaManager } from 'vue-meta'

let app = createApp(App)
app.config.globalProperties.confCookieExpired = 1000 * 60 * 24 * 365
app.config.globalProperties.confStoragePrefix = 'sugarstar_'
app.config.globalProperties.confStorageExpired = 1000 * 60 * 24 * 365
app.config.globalProperties.confTokenExpired = 1000 * 60 * 1
app.config.globalProperties.confDeviceType = 'mobile' // pc, mobile, auto

app.config.globalProperties.confApiUrl = 'http://sugarstar-admin.weblab.idv.tw/' //devel
app.config.globalProperties.confWsHost = 'ws://sugarstar-websocket.weblab.idv.tw' // devel
app.config.globalProperties.confApiUrl = 'http://sugarstar-admin.weblab.idv.tw/' //devel



// app.config.globalProperties.confApiUrl = 'https://sugarstar-admin.sugarstar.cc/' //prod
app.config.globalProperties.lineNotify = 'https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=Dc45ZrAVfpCCS5Ajt8Uw0m&redirect_uri=' + encodeURI('https://sugarstar.cc/callback/line') + '&scope=notify&state=' //prod
// app.config.globalProperties.confWsHost = 'wss://sugarstar-ws.sugarstar.cc' //prod
app.config.globalProperties.confReCAPTCHAKey = '6LejVuUeAAAAAEH4mTgysRxQMh_naxDGbIdGxZEs' //prod
app.config.globalProperties.confFaceBookAppId = '1026121887987016' //prod
app.config.globalProperties.confEcpayApiUrl = 'https://payment.ecpay.com.tw/Cashier/AioCheckOut/V5' //prod

app.use(router)
    .use(store)
    .use(Vue3Storage)
    .use(VueNativeSock, app.config.globalProperties.confWsHost, {
        store: store,
        format: 'json',
        reconnection: true,
        reconnectionAttempts: 10000,
        reconnectionDelay: 1000,
    })
    .use(Toaster, {
        position: "bottom",
        dismissible: true,
        maxToasts: 3
    })
    .use(createMetaManager())
    .mount('#app')



