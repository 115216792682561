<template>
    <div className="dev-info">

    </div>
</template>
<script>
import {v4 as uuidv4} from "uuid"
import axios from "axios";
import {useCookies} from "vue3-cookies";

export default {
    name: 'UserCommon',
    setup() {
        const {cookies} = useCookies();
        return {cookies};
    },
    data() {
        return {
            isLogin: false
        }
    },
    created() {
        this.checkUuid()
        this.checkToken()
        this.$watch(
            () => this.$route.path,
            () => {
                let currentPath = this.$route.path
                if (
                    currentPath !== '/' &&
                    currentPath !== '/login' &&
                    currentPath !== '/privacy_policy' &&
                    currentPath !== '/dating_reminder' &&
                    currentPath !== '/psychological_test' &&
                    currentPath !== '/free_vip' &&
                    !((this.$route.path.match('/order_result/\\d+') && this.$route.path.match('/order_result/\\d+').length > 0)) &&
                    !((this.$route.path.match('/ecpay/\\d+') && this.$route.path.match('/ecpay/\\d+').length > 0)) &&
                    !((this.$route.path.match('/ecpay_wines/\\d+') && this.$route.path.match('/ecpay_wines/\\d+').length > 0))
                ) {
                    this.$storage.setStorageSync(this.confStoragePrefix + "lastPath", this.$route.path, this.confStorageExpired);
                }
            }
        )
    },
    components: {},
    methods: {
        async checkUuid() {
            if (!this.$storage.getStorageSync(this.confStoragePrefix + 'uuid')) {
                await this.$storage.setStorageSync(this.confStoragePrefix + "uuid", uuidv4(), this.confStorageExpired);
            }
            await this.$store.commit(
                'setAxiosHeaders',
                {
                    key: 'uuid',
                    val: this.$storage.getStorageSync(this.confStoragePrefix + 'uuid')
                }
            )
            this.cookies.set("uuid", encodeURI(this.$storage.getStorageSync(this.confStoragePrefix + 'uuid')));
        },
        async checkToken() {
            let token = await this.$storage.getStorageSync(this.confStoragePrefix + 'token')
            let validToken = await this.$storage.getStorageSync(this.confStoragePrefix + 'validToken')
            let uuid = await this.$storage.getStorageSync(this.confStoragePrefix + 'uuid')
            if (token && !validToken) {
                await axios
                    .get(
                        this.confApiUrl + 'api/check_token',
                        {
                            headers: {
                                'Authorization': 'Bearer ' + token,
                                'uuid': uuid
                            }
                        }
                    )
                    .then(response => (this.setToken(response)))
                    .catch(function (error) {
                        console.error('checkToken', error);
                    });
            } else if (validToken !== undefined) {

                await this.$store.commit(
                    'setAxiosHeaders',
                    {
                        key: 'Authorization',
                        val: 'Bearer ' + validToken.token
                    }
                )
                await this.$store.commit(
                    'setAxiosHeaders',
                    {
                        key: 'user',
                        val: validToken.userinfo.name
                    }
                )
                await this.$store.commit('setUserInfo', validToken.userinfo)
                await this.$store.commit('setIsLogin', true)
                this.isLogin = true
            } else {
                await this.clearToken()
            }
        },
        async setToken(response) {
            if (response.data.success) {
                let token = await this.$storage.getStorageSync(this.confStoragePrefix + 'token')
                await this.$store.commit(
                    'setAxiosHeaders',
                    {
                        key: 'Authorization',
                        val: 'Bearer ' + token
                    }
                )
                await this.getUserInfo()
            } else {
                await this.clearToken()
            }
            await this.$storage.setStorageSync(this.confStoragePrefix + 'validToken', {
                axiosHeaders: this.$store.state.axiosHeaders,
                token: await this.$storage.getStorageSync(this.confStoragePrefix + 'token'),
                userinfo: await this.$storage.getStorageSync(this.confStoragePrefix + 'userInfo'),
            }, this.confTokenExpired)
            this.isLogin = true
            if (this.$storage.getStorageSync(this.confStoragePrefix + 'lastPath')) {
                await this.$router.push({path: this.$storage.getStorageSync(this.confStoragePrefix + 'lastPath')})
            }
        },
        async getUserInfo() {
            await axios
                .get(
                    this.confApiUrl + 'api/user',
                    {
                        headers: this.$store.state.axiosHeaders
                    }
                )
                .then(response => (this.setUserInfo(response)))
                .catch(function (error) {
                    console.error('getUserInfo', error);
                });
        },
        async setUserInfo(response) {
            if (
                response.status === 200 &&
                response.data.name
            ) {
                await this.$store.commit('setUserInfo', response.data)
                await this.$store.commit('setIsLogin', true)
                await this.$storage.setStorageSync(this.confStoragePrefix + 'userInfo', response.data, this.confStorageExpired)
                await this.$store.commit(
                    'setAxiosHeaders',
                    {
                        key: 'user',
                        val: response.data.name
                    }
                )
            }
        },
        async clearToken() {
            await this.$storage.removeStorageSync(this.confStoragePrefix + 'token')
            await this.$storage.removeStorageSync(this.confStoragePrefix + 'userInfo')
            await this.$store.commit(
                'setAxiosHeaders',
                {
                    key: 'Authorization',
                    val: null
                }
            )
            await this.$store.commit(
                'setAxiosHeaders',
                {
                    key: 'user',
                    val: null
                }
            )
            await this.$store.commit('setIsLogin', false)
            await this.$store.commit('setUserInfo', {})
        }
    },

}
</script>