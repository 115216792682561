<template>
    <div v-if="deviceType==='mobile'" class="main-content-cain">
        <mobile-home-nav-bar></mobile-home-nav-bar>
        <mobile-home-cover></mobile-home-cover>
        <mobile-home-content></mobile-home-content>
        <mobile-home-star-female></mobile-home-star-female>
        <mobile-home-star-male></mobile-home-star-male>
        <!--        <mobile-home-vips></mobile-home-vips>-->
        <mobile-home-bottom></mobile-home-bottom>
        <mobile-main-footer></mobile-main-footer>
        <mobile-main-copyright></mobile-main-copyright>
    </div>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: 'HomeViews',
    components: {
        mobileHomeNavBar: defineAsyncComponent(() =>
            import('@/components/mobile/HomeNavBar.vue')
        ),
        mobileHomeCover: defineAsyncComponent(() =>
            import('@/components/mobile/HomeCover.vue')
        ),
        mobileHomeContent: defineAsyncComponent(() =>
            import('@/components/mobile/HomeContent.vue')
        ),
        // mobileHomeVips: defineAsyncComponent(() =>
        //     import('@/components/mobile/HomeVips.vue')
        // ),
        mobileHomeStarFemale: defineAsyncComponent(() =>
            import('@/components/mobile/HomeStarFemale.vue')
        ),
        mobileHomeStarMale: defineAsyncComponent(() =>
            import('@/components/mobile/HomeStarMale.vue')
        ),
        mobileHomeBottom: defineAsyncComponent(() =>
            import('@/components/mobile/HomeBottom.vue')
        ),
        mobileMainFooter: defineAsyncComponent(() =>
            import('@/components/mobile/MainFooter.vue')
        ),
        mobileMainCopyright: defineAsyncComponent(() =>
            import('@/components/mobile/MainCopyright.vue')
        ),
    },
    data() {
        return {
            deviceType: '',
            isLogin: false
        }
    },
    async mounted() {
        this.deviceType = this.$store.state.deviceType
        this.login = await this.$store.state.isLogin
        if (this.login) {
            if (this.$storage.getStorageSync(this.confStoragePrefix +'lastPath')) {
                await this.$router.push({path: this.$storage.getStorageSync(this.confStoragePrefix +'lastPath')})
            }else{
                await this.$router.push({path: '/members'})
            }
        }
    }

}
</script>
