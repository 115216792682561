import {createRouter, createWebHistory} from 'vue-router'
import HomeViews from '@/views/HomeViews.vue'

const routes = [
    {
        path: '/',
        name: 'HomeViews',
        component: HomeViews
    },
    {
        path: '/privacy_policy',
        name: 'PrivacyPolicy',
        component: () => import(/* webpackChunkName: "privacy_policy" */ '@/views/PrivacyPolicyViews.vue')
    },
    {
        path: '/login',
        name: 'LoginViews',
        component: () => import(/* webpackChunkName: "login" */ '@/views/LoginViews.vue')
    },
    {
        path: '/signup/:arrs*',
        name: 'SignupViews',
        component: () => import(/* webpackChunkName: "signup" */ '@/views/SignUpViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/user_profile/:arrs*',
        name: 'UserViews',
        component: () => import(/* webpackChunkName: "signup" */ '@/views/UserViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/free_vip',
        name: 'FreeVipViews',
        component: () => import(/* webpackChunkName: "male_more" */ '@/views/FreeVipViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/email/:arrs*',
        name: 'MailVerifyViews',
        component: () => import(/* webpackChunkName: "mail_verify_views" */ '@/views/MailVerifyViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/reset_password/:arrs*',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "reset_password" */ '@/views/ResetPasswordViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/search',
        name: 'SearchViews',
        component: () => import(/* webpackChunkName: "search" */ '@/views/SearchViews.vue'),
    },
    {
        path: '/filter',
        name: 'FilterViews',
        component: () => import(/* webpackChunkName: "search" */ '@/views/FilterViews.vue'),
    },
    {
        path: '/psychological_test',
        name: 'PsychologicalTestViews',
        component: () => import(/* webpackChunkName: "psychological_test" */ '@/views/PsychologicalTestViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/facebook_login',
        name: 'FacebookLogin',
        component: () => import(/* webpackChunkName: "line_callback" */ '@/views/FacebookLoginViews.vue')
    },
    {
        path: '/chat/:arrs*',
        name: 'ChatViews',
        component: () => import(/* webpackChunkName: "chat_views" */ '@/views/ChatBoxViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/chatUser/:arrs*',
        name: 'ChatUserViews',
        component: () => import(/* webpackChunkName: "chat_views" */ '@/views/ChatUserViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/email_verify/:arrs*',
        name: 'EmailVerifyViews',
        component: () => import(/* webpackChunkName: "chat_box" */ '@/views/EmailVerifyViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/callback/line',
        name: 'CallbackLineViews',
        component: () => import(/* webpackChunkName: "CallbackLineViews" */ '@/views/LineCallBackViews.vue')
    },
    {
        path: '/coins',
        name: 'CoinsViews',
        component: () => import(/* webpackChunkName: "vip" */ '@/views/CoinsViews.vue')
    },
    {
        path: '/vip',
        name: 'VipViews',
        component: () => import(/* webpackChunkName: "vip" */ '@/views/VipViews.vue')
    },
    {
        path: '/info',
        name: 'infoViews',
        component: () => import(/* webpackChunkName: "info" */ '@/views/ProfileView.vue')
    },
    {
        path: '/account',
        name: 'AccountViews',
        component: () => import(/* webpackChunkName: "account" */ '@/views/AccountViews.vue')
    },
    {
        path: '/order_result/:arrs*',
        name: 'OrderResultViews',
        component: () => import(/* webpackChunkName: "OrderResult" */ '@/views/OrderResultViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/notify/:arrs*',
        name: 'NotifyViews',
        component: () => import(/* webpackChunkName: "notify" */ '@/views/NotifyViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/match/:arrs*',
        name: 'MatchViews',
        component: () => import(/* webpackChunkName: "email" */ '@/views/MatchViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/favorite',
        name: 'FavoriteViews',
        component: () => import(/* webpackChunkName: "favorite" */ '@/views/FavoriteViews.vue'),
    },
    {
        path: '/see',
        name: 'SeeViews',
        component: () => import(/* webpackChunkName: "see" */ '@/views/SeeViews.vue'),
    },
    {
        path: '/gift',
        name: 'GiftViews',
        component: () => import(/* webpackChunkName: "gift" */ '@/views/GiftViews.vue'),
    },
    {
        path: '/members/:arrs*',
        name: 'MembersViews',
        component: () => import(/* webpackChunkName: "member" */ '@/views/MembersViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/party',
        name: 'PartyViews',
        component: () => import(/* webpackChunkName: "party" */ '@/views/PartyViews.vue'),
    },
    {
        path: '/blog',
        name: 'BlogViews',
        component: () => import(/* webpackChunkName: "blog" */ '@/views/BlogViews.vue'),
    },
    {
        path: '/forget_password',
        name: 'ForgetPasswordViews',
        component: () => import(/* webpackChunkName: "forget_password" */ '@/views/ForgetPasswordViews.vue')
    },
    {
        path: '/logout',
        name: 'LogoutViews',
        component: () => import(/* webpackChunkName: "logout" */ '@/views/LogoutViews.vue')
    },
    {
        path: '/ecpay/:arrs*',
        name: 'EcPay',
        component: () => import(/* webpackChunkName: "ecpay" */ '@/views/EcPayViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/ecpay_wines/:arrs*',
        name: 'EcPayWine',
        component: () => import(/* webpackChunkName: "ecpay_wines" */ '@/views/EcPayWineViews.vue'),
        props: route => {
            const params = route.params
            const props = {}
            for (let i = 0; i < params.length; i++) {
                props[params[i]] = i + 1 < params.length ? params[i + 1] : null
            }
            return props
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/party',
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
