import {createStore} from 'vuex'

export default createStore({
    state: {
        newBlog: false,
        newParty: false,
        party:[],
        isLoading: true,
        reqHost: '',
        deviceType: '',
        deviceWidth: 0,
        axiosHeaders: {},
        isLogin: false,
        isVip: false,
        vipEne: '',
        userInfo: {},
        photos: {},
        like: 0,
        see: 0,
        favor: 0,
        toUserId: false,
        chatNewDot: false,
        filerList: false,
        freshFav: false,
        coin: 0,
        gift: 0,
        notifyFresh: false,
        coinFresh: false,
        freeChat: false,
        socket: {
            isConnected: false,
            message: {
                login: {
                    return: null
                },
                lists: [],
                msg: '',
                msg_to: '',
                notify: [],
                warning: '',
                duplicate: false,
            },
            reconnectError: false,
            heartBeatInterval: 60000,
            heartBeatTimer: 0,
            reconnectState: '',
            reconnectCount: 0
        },
    },
    getters: {
        getDeviceType: state => {
            return state.deviceType;
        },
        getToken: state => {
            return state.axiosHeaders.Authorization
        }
    },
    mutations: {
        setHost(state, payload) {
            state.reqHost = payload
        },
        setLoading(state, payload) {
            state.isLoading = payload
        },
        setDeviceType(state, payload) {
            state.deviceType = payload
        },
        detectDeviceType(state, payload) {
            payload > 992 ? state.deviceType = 'pc' : false
            payload < 992 ? state.deviceType = 'mobile' : false
        },
        setDeviceWidth(state, payload) {
            state.deviceWidth = payload
        },
        setFreeChat(state, payload) {
            state.freeChat = payload
        },
        setAxiosHeaders(state, payload) {
            if (payload.val) {
                state.axiosHeaders[payload.key] = payload.val;
            } else {
                delete state.axiosHeaders[payload.key];
            }
        },
        setNewFav(state, payload) {
            state.freshFav = payload
        },
        setToUserId(state, payload) {
            state.toUserId = payload
        },
        setIsLogin(state, payload) {
            state.isLogin = payload
        },
        setCoin(state, payload) {
            state.coin = payload
        },
        setGift(state, payload) {
            state.gift = payload
        },
        setIsVip(state, payload) {
            state.isVip = payload
        },
        setCoinFresh(state, payload) {
            state.coinFresh = payload
        },
        setNotifyFresh(state, payload) {
            state.notifyFresh = payload
        },
        setVipEnd(state, payload) {
            state.vipEnd = payload
        },
        setUserInfo(state, payload) {
            state.userInfo = payload
        },
        setUserLike(state, payload) {
            state.like = payload
        },
        setUserSee(state, payload) {
            state.see = payload
        },
        setUserFavor(state, payload) {
            state.favor = payload
        },
        setUserPhotos(state, payload) {
            state.photos = payload
        },
        setChatNewDot(state, payload) {
            state.chatNewDot = payload
        },
        setNewBlog(state, payload) {
            state.newBlog = payload
        },
        setNewParty(state, payload) {
            state.newParty = payload
        },
        SOCKET_ONOPEN(state, payload) {
            const socket = payload.currentTarget
            state.socket.isConnected = true;
            state.socket.heartBeatTimer = setInterval(() => {
                state.socket.isConnected &&
                socket.sendObj({
                    type: 'heartbeat'
                });
            }, state.socket.heartBeatInterval);
        },
        SOCKET_ONCLOSE(state) {
            state.socket.isConnected = false;
            clearInterval(state.socket.heartBeatTimer);
            state.socket.heartBeatTimer = 0;
        },
        SOCKET_ONERROR(state, payload) {
            console.error(state, payload);
        },
        SOCKET_ONMESSAGE(state, message) {
            state.socket.message = message;
        },
        SOCKET_RECONNECT(state, count) {
            this.reconnectState = state
            this.reconnectCount = count
            // console.info("消息系统重连中...", state, count);
        },
        SOCKET_RECONNECT_ERROR(state) {
            state.socket.reconnectError = true;
        }
    },
    actions: {},
    modules: {}
})
